<template>
  <div class="page page--promo animate__slow">
    <template v-for="step in steps">
      <transition :enter-active-class="$store.state.enterClassName" :leave-active-class="$store.state.leaveClassName">
        <div class="page animate__slow" v-if="step.id == showStep" key="step.id" :class="{ loaded: isSubPageLoaded }">

          <div class="presentation presentation--1" v-if="step.id == 1">
            <div class="presentation-video-block">
              <div class="m25 presentation-video" :style="step.bgStyle">
                <vimeo-player v-if="step.data.vimeoId" ref="player" class="video-block" :class="{active: vimeoPlayerStart == true}" :video-id="step.data.vimeoId" :options="vimeoOptions" @ready="onVimeoReady" @play="onVimeoStart" @pause="play" />

                <div class="presentation-video__btn" v-if="!isMobile">
                  <a :href="step.data.vimeoLink" data-fancybox class="btn"><span>{{ get('btnFilm') }}</span></a>
                </div>
              </div>
            </div>
            <div class="presentation__content">
              <div class="presentation__title" v-html="step.title"></div>
              <div class="presentation__text" v-html="step.text"></div>
              <div class="btns btns--3" v-if="isMobile">
                <a :href="step.data.vimeoLink" data-fancybox class="btn"><span>{{ get('btnFilm') }}</span></a>
              </div>
            </div>
            <div class="presentation__voile m75"></div>
          </div>

          <div class="presentation presentation--2" v-else-if="step.id == 2" :class="presentation2Class">
            <div class="presentation__pic m25" :style="step.bgStyle">
              <div class="presentation__title" v-html="step.title"></div>

              <template v-for="(item, index) in step.data.list" v-if="!isMobile">
                <div class="presentation2" :class="presentation2ItemClass(item, index)">
                  <div class="presentation2__letter">{{ item.letter }}</div>
                  <div class="presentation2__text hypher" v-html="item.text"></div>
                </div>
              </template>
            </div>
            <div class="presentation__block">
              <template v-for="item in step.data.list">
                <div class="presentation2-title hover hover--big"
                       v-on:mouseover="presentation2Over(event, item)"
                       v-on:mouseleave="presentation2Leave(step)"
                       :class="{active: item.hover == true}"
                  >{{ item.name }}
                </div>
              </template>
            </div>
            <div class="presentation2-float" v-if="!isMobile" :class="{active: presentation2Hover == true}">
              <template v-for="step in steps">
                <template v-if="step.id == 2">
                  <div v-for="item in step.data.list" class="presentation2-float__img" :style="item.imgStyle" :class="{active: item.hover == true}"></div>
                </template>
              </template>
            </div>

            <template v-for="(item, index) in step.data.list" v-if="isMobile">
              <transition :enter-active-class="$store.state.enterClassName" :leave-active-class="$store.state.leaveClassName">
                <div class="presentation2" v-if="item.hover == true" :class="presentation2ItemClass(item, index)">
                  <div class="presentation2__img" :style="item.imgStyle"></div>
                  <div class="presentation2__letter">{{ item.letter }}</div>
                  <div class="presentation2__content">
                    <div class="presentation2__title">{{ item.name }}</div>
                    <div class="presentation2__text hypher" v-html="item.text"></div>
                  </div>
                </div>
              </transition>
            </template>
          </div>

          <div class="presentation presentation--3" v-else-if="step.id == 3">
            <div v-if="!isMobile" class="presentation__title" v-html="step.title"></div>
            <div v-if="isMobile" class="m50">
              <div class="presentation__title" v-html="step.titleMobile"></div>
            </div>

            <div class="years-voile" v-if="yearsVoile && !isMobile" @click="yearsClose(step)"></div>

            <div class="years">
              <div v-for="(year, num) in step.data.years" class="year" :year="num" :class="yearClass(num, year)" @click="yearOpen(num, step)">
                <div class="year__inner" :class="yearClassTransition()">
                  <div class="year__num">{{ num }}</div>
                  <div class="year__content" v-if="!isMobile">
                    <div class="year__text" v-html="year.text"></div>
                    <div class="year__link" v-if="year.route">
                      <router-link class="btn btn--md" :to="{ name: year.route }"><span>{{ year.routeText }}</span></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="years-texts" v-if="isMobile">
              <div v-for="(year, num) in step.data.years" class="year-modal" :class="yearModalClass(num, year, true)">
                <div class="year__close" @click="yearsClose(step)"><img src="../assets/images/plus.svg"></div>
                <div class="year__text" v-html="year.text"></div>
                <div class="year__link" v-if="year.route">
                  <router-link class="btn btn--md" :to="{ name: year.route }"><span>{{ year.routeText }}</span></router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="presentation presentation--4" v-else-if="step.id == 4">
            <template v-if="!isMobile">
              <div class="slider-pages m100">
                <span class="slider-pages__current">{{ sliderIndex+1 }}</span>/<span class="slider-pages__all">{{ step.data.list.length }}</span>
              </div>

              <swiper class="slider-aspiration"
                  :slides-per-view="swiperOptions.slidesPerView"
                  :speed="swiperOptions.speed"
                  :navigation="{nextEl: '.slider-next', prevEl: '.slider-prev'}"
                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
              >
                <swiper-slide v-for="(slide, index) in step.data.list">
                  <div class="slider-aspiration-item" :class="sliderAspirationClass(slide, index, step)" @click="aspirationSlide(index)">{{ slide.title }}</div>
                </swiper-slide>
              </swiper>

              <div class="aspirations m25">
                  <template v-for="(aspiration, index) in step.data.list">
                    <transition name="fade">
                      <div class="aspiration" :class="aspirationClass(index, aspiration)">
                        <div class="aspiration__text hypher" v-html="aspiration.text"></div>
                        <div class="aspiration__pic m25" :style="aspiration.imgStyle"></div>
                      </div>
                    </transition>
                  </template>
              </div>

              <div class="slider-prev hover hover--big hover--arrow-prev" :style="$store.state.sliderPrevStyle"></div>
              <div class="slider-next hover hover--big hover--arrow-next" :style="$store.state.sliderNextStyle"></div>
            </template>
            <template v-if="isMobile">
              <div v-for="(aspiration, index) in step.data.list" class="aspiration m25">
                <div class="aspiration__title">{{ aspiration.title }}</div>
                <div class="aspiration__pic" :style="aspiration.imgStyle"></div>
                <div class="aspiration__text hypher" v-html="aspiration.text"></div>
              </div>
            </template>
          </div>

          <div class="presentation presentation--5" v-else>
            <div class="presentation__pic presentation__pic--video m25" :style="step.bgStyle" v-if="!isMobile">
              <vimeo-player v-if="step.data.vimeoId" ref="player" class="video-block video-block--full" :class="{active: vimeoPlayerStart == true}" :video-id="step.data.vimeoId" :options="vimeoOptions" @ready="onVimeoReady" @play="onVimeoStart"/>
            </div>
            <div class="presentation-video-block" v-if="isMobile">
              <div class="m25 presentation-video" :style="step.bgStyle">
                <vimeo-player v-if="step.data.vimeoId" ref="player" class="video-block" :class="{active: vimeoPlayerStart == true}" :video-id="step.data.vimeoId" :options="vimeoOptions" @ready="onVimeoReady" @play="onVimeoStart" @pause="play" />
              </div>
            </div>
            <div class="presentation__block">
              <div class="presentation__title">{{ step.title }}</div>

              <div class="form form--mt">
                <div class="form__row" v-for="(elem, index) in get('formFeedback')">
                  <label class="form-elem" :class="{focused: elem.focused == true}">
                    <div class="form-elem__title">{{ elem.title }}</div>
                    <div class="form-elem__input">
                      <input v-model="formElems['formFeedback'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-if="!elem.mask" @focus="elem.focused = true" @blur="elem.focused = false">
                      <input v-model="formElems['formFeedback'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-else @focus="elem.focused = true" @blur="elem.focused = false" v-maska="'+7 (###) ###-##-##'">
                    </div>
                  </label>
                </div>
                <div class="form__btns">
                  <span class="btn" :class="{'in-action': formInAction == true, 'btn--md': !isMobile, 'btn--sm': isMobile}" @click="sendForm('formFeedback', false)"><span>{{ step.data.btn }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>

    <div :class="{ loaded: isLoaded }">
      <div class="navigation" :class="{active: openNavigationMenu == true, m100: !isMobile}">
        <div class="navigation__menu">
          <template v-for="step in steps">
            <div class="navigation__menu-item hover" @click="changeStep(step.id, true)" :class="{active: step.id == showStep}">{{ step.menuItem }}</div>
          </template>
        </div>

        <div class="navigation__block">
          <div class="navigation__title" v-if="subPageIndex == 1 && !isMobile" v-html="get('navigationTitle')"></div>

          <div class="navigation__prev hover hover--big" v-if="subPageIndex != 1 || isMobile" :class="{disable: showStep == firstPage}" @click="changeStep('prev')"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>

          <div class="navigation__plus hover hover--big" @click="navigationToggle()" :class="{active: openNavigationMenu == true}"><svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M84.643 44.443H44.501v40.142h-3.797V44.443H.562v-3.797h40.142V.504h3.797v40.142h40.142v3.797z"/></svg></div>

          <div class="navigation__next" :class="navigationNextClass()" @click="changeStep('next')"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>
        </div>
      </div>

      <div class="pagination m100" v-if="!isMobile">
        <div class="pagination__line">
          <div class="pagination__pan" :style="paginationPanWidth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Promo',
  mixins: [loader, isMobile, functions],
  emits: ['loaded', 'hideContactCenterMobile', 'openModal', 'showContactCenterMobile'],
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      pageData: {page: 'Promo'},
      showStep: 1,
      subPageIndex: 1,
      steps: [],
      vimeoOptions: {
        background: true,
        //muted: true,
        autoplay: true,
        loop: true,
      },
      vimeoPlayerReady: false,
      vimeoPlayerStart: false,
      openNavigationMenu: false,
      presentation2Hover: false,
      swiperOptions: {
        slidesPerView: 'auto',
        speed: 1000
      },
      sliderIndex: 0,
      aspirationSwiper: false,
      yearsVoile: false,
      formInAction: false,
      formElems: {
        formFeedback: []
      },
    }
  },

  activated() {
    let _t = this;

    $('body').mousemove(function(e){
      let elem = $(".presentation2-float");

      TweenMax.to(elem, 2, {left:e.clientX - elem.width() / 3, top:e.clientY - elem.height() / 2, ease:Elastic.linear});

      let center = {
        x: parseInt($(window).width() / 2),
        y: parseInt($(window).height() / 2),
      };

      let directionX = e.clientX > center.x ? 1 : -1;
      let directionY = e.clientY > center.y ? 1 : -1;

      let transformX = directionX * e.clientX / $(window).width()
      let transformY = directionY * e.clientY / $(window).width();

      $('.year').each(function () {
        let year = $(this);//.find('.year__inner');
        let yearInner = year.find('.year__inner');

        if (!year.data('speed')) {
          year.data('speed', _t.randomInt(30) / 10 + 1);
        }

        let maxX = 1;
        let maxY = 1;

        if (year.hasClass('year--3')) {
          if (directionY < 0) {
            maxY = .4
          }
        }
        if (year.hasClass('year--8')) {
          if (directionX > 0) {
            maxX = .6
          }
        }
        if (year.hasClass('year--9')) {
          if (directionX > 0) {
            maxX = .2
          }
          if (directionY < 0) {
            maxY = .3
          }
        }
        if (year.hasClass('year--10')) {
          if (directionX > 0) {
            maxX = .5
          }
        }

        let marginLeft = parseInt(year.width() * maxX) * transformX;
        let marginTop = parseInt(year.height() * maxY) * transformY;

        if (year.hasClass('open')) {
          marginLeft = 0;
          marginTop = 0;
        }

        if (!_t.isMobile) {
          TweenMax.to(yearInner, year.data('speed'), {
            marginLeft: marginLeft,
            marginTop: marginTop,
            ease: Elastic.linear
          });
        }
      });
    });
  },

  deactivated() {
    let _t = this;
    _t.subPageIndex = 1;
    _t.showStep = 1;

    _t.$store.commit('changeMode');
    _t.$store.commit('changeBtnContactCenterPosition');
    _t.$store.commit('changeBtnContactCenterClass');

    _t.$store.commit('toggleHamburger', false);
    _t.$store.commit('togglePresentationClose', false);
    _t.$emit('showContactCenterMobile');
  },

  computed: {
    lastPage() {
      var _t = this;

      return _t.steps[_t.steps.length - 1] && _t.steps[_t.steps.length - 1].id
    },
    firstPage() {
      return this.steps[0] && this.steps[0].id
    },
    subPagesCount() {
      return this.steps.length
    },
    paginationPanWidth() {
      let _t = this;

      return 'width:' + (_t.subPageIndex / _t.subPagesCount) * 100 + '%';
    },
    presentation2Class() {
      let classes = {};

      classes['presentation--hover'] = !!this.presentation2Hover && !this.isMobile;
      //classes['presentation--hover-' + this.presentation2Hover] = true;

      return classes;
    },
  },

  watch: {
    showStep: function (val) {
      this.watchStep(val);
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.steps = _t.get('steps');
      _t.videoID = _t.get('presentationVideoId');

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        var images = [];

        for (let i in _t.steps) {
          if (_t.steps[i].bg) {
            _t.steps[i].bgStyle = _t.bgImgStyle(_t.steps[i].bg);

            for (let j in _t.steps[i].bg) {
              images.push(_t.steps[i].bg[j]);
            }
          }
          if (_t.steps[i].data && _t.steps[i].data.list) {
            for (let j in _t.steps[i].data.list) {
              _t.steps[i].data.list[j].imgStyle = _t.bgImgStyle(_t.steps[i].data.list[j].img);

              for (let q in _t.steps[i].data.list[j].img) {
                images.push(_t.steps[i].data.list[j].img[q]);
              }
            }
          }
        }

        _t.loadImages(images);
      }
    },
    watchStep: function(val){
      var _t = this;

      _t.isSubPageLoaded = false;

      _t.vimeoPlayerReady = false;
      _t.vimeoPlayerStart = false;

      setTimeout(() => {
        _t.isSubPageLoaded = true;
        _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile});
      }, 100);

      setTimeout(function() {
        $('.hypher').hyphenate('ru');
        $('.hypher > *').hyphenate('ru');
      }, 500);

      for (let i in _t.steps[2].data.years) {
        _t.steps[2].data.years[i].open = false;
      }

      for (let i in _t.steps[3].data.list) {
        _t.steps[3].data.list[i].prev = false;
        _t.steps[3].data.list[i].next = i >= 1;
      }
    },
    showFirstStep() {
      this.changeStep(this.steps[0].id, true)
    },
    changeStep: function(delta, exactStep){
      let _t = this;

      _t.openNavigationMenu = false;

      let useReversAnimation = delta == 'prev' || (exactStep && delta < _t.showStep);

      if (useReversAnimation) {
        _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile, rightOut: true});
      }

      _t.$emit('showContactCenterMobile');

      if (!exactStep) {
        for (let i in _t.steps) {
          if (delta == 'prev') {
            if (_t.steps[i * 1 + 1] && _t.steps[i * 1 + 1].id == _t.showStep) {
              _t.showStep = _t.steps[i].id;
              _t.subPageIndex--;
              break;
            }
          } else {
            if (_t.steps[i * 1 - 1] && _t.steps[i * 1 - 1].id == _t.showStep) {
              _t.showStep = _t.steps[i].id;
              _t.subPageIndex++;
              break;
            }
          }
        }
      } else {
        _t.showStep = delta;

        for (let i in _t.steps) {
            if (_t.steps[i].id == delta) {
              _t.subPageIndex = i*1 + 1;
              break;
          }
        }
      }

      let currentStep = _t.steps[_t.subPageIndex - 1];
      let btnContactCenterPosition = currentStep.data && currentStep.data.btnContactCenterPosition ? currentStep.data.btnContactCenterPosition : false;
      let btnContactCenterClass = currentStep.data && currentStep.data.btnContactCenterClass ? currentStep.data.btnContactCenterClass : false;

      let mode = false;

      if (_t.isMobile && currentStep.modeMobile) {
        mode = currentStep.modeMobile
      }
      if (!_t.isMobile && currentStep.mode) {
        mode = currentStep.mode
      }

      _t.$store.commit('changeMode', mode);
      _t.$store.commit('changeBtnContactCenterPosition', btnContactCenterPosition);
      _t.$store.commit('changeBtnContactCenterClass', btnContactCenterClass);


      _t.$store.commit('toggleHamburger', false);
      _t.$store.commit('togglePresentationClose', false);
    },
    onVimeoReady() {
      this.vimeoPlayerReady = true
    },
    onVimeoStart() {
      this.vimeoPlayerStart = true
    },
    play () {
      if (this.$refs.player) {
        this.$refs.player.play()
      }
    },
    pause () {
      if (this.$refs.player) {
        this.$refs.player.pause()
      }
    },
    presentation2Over(event, item) {
      let _t = this;

      _t.presentation2Hover = true;
      item.hover = true;

      if (!_t.isMobile) {
        _t.$store.commit('changeMode', _t.$store.state.modeDefault);
        _t.$store.commit('changeBtnContactCenterPosition', -1);
      } else {
        _t.$store.commit('toggleHamburger', true);
        _t.$store.commit('togglePresentationClose', true);
      }
    },
    presentation2Leave(step) {
      let _t = this;

      if (_t.isMobile) {
        return;
      }

      _t.presentation2Hover = false;

      for (let i in step.data.list) {
        step.data.list[i].hover = false;
      }

      let currentStep = _t.steps[_t.subPageIndex - 1];

      _t.$store.commit('changeMode', currentStep.mode ? currentStep.mode : false);
      _t.$store.commit('changeBtnContactCenterPosition', currentStep.data && currentStep.data.btnContactCenterPosition ? currentStep.data.btnContactCenterPosition : false);
    },
    yearsClose(step) {
      let _t = this;

      for (let i in step.data.years) {
        step.data.years[i].show = false;
        step.data.years[i].openModal = false;
      }
      setTimeout(function () {
        for (let i in step.data.years) {
          step.data.years[i].open = false;
        }
      }, 600);

      _t.yearsVoile = false;
    },
    yearOpen(num, step) {
      let _t = this;

      _t.yearsVoile = true;

      for (let i in step.data.years) {
        step.data.years[i].open = false;
        step.data.years[i].openModal = false;
      }

      if (_t.isMobile) {
        step.data.years[num].openModal = true;
      } else {
        step.data.years[num].open = true;

        let year = $('.year[year='+ num +']');
        let yearInner = year.find('.year__inner');

        TweenMax.to(yearInner, year.data('speed'), {
          marginLeft: 0,
          marginTop: 0,
          ease: Elastic.linear
        });

        setTimeout(function () {
          step.data.years[num].show = true;
        }, 600);
      }
    },
    yearClass(num, year) {
      let classes = {};

      classes['year--' + num] = true;
      classes['hover'] = year.open != true;
      classes['open'] = year.open == true;
      classes['active'] = year.openModal == true;
      classes['show'] = year.show == true;

      return classes;
    },
    yearModalClass(num, year) {
      let classes = {};

      classes['open-modal'] = year.openModal == true;

      return classes;
    },
    yearClassTransition() {
      let classes = {};

      classes['year-transition'] = true;
      classes['year-transition-' + this.randomInt(5)] = true;

      return classes;
    },
    onSlideChange(swiper) {
      let _t = this;
      _t.sliderIndex = swiper.activeIndex

      for (let i in _t.steps[3].data.list) {
        _t.steps[3].data.list[i].prev = i < _t.sliderIndex;
        _t.steps[3].data.list[i].next = i > _t.sliderIndex;
      }
    },
    presentation2ItemClass(item, index) {
      let classes = {};

      classes['active'] = item.hover == true;
      classes['presentation2--' + index] = true;

      return classes;
    },
    aspirationClass(index, aspiration) {
      let classes = {};

      classes['active'] = this.sliderIndex == index;
      classes['aspiration--' + index] = true;
      classes['aspiration--reverse'] = index % 2 == 1;
      classes['aspiration--prev'] = aspiration.prev == true;
      classes['aspiration--next'] = aspiration.next == true;

      return classes;
    },
    onSwiper(swiper) {
      this.aspirationSwiper = swiper
    },
    aspirationSlide(index) {
      let _t = this;
      _t.aspirationSwiper.slideTo(index)
    },
    sliderAspirationClass(slide, index, step) {
      return {
        last: index == step.data.list.length - 1,
        "hover" : this.sliderIndex != index,
        "hover--big" : this.sliderIndex != index,
      }
    },
    navigationNextClass() {
      let _t = this;

      if (_t.showStep == _t.lastPage) {
        $('.cursor').removeClass('cursor--hover cursor--hover-big');
      }

      return {
        disable: _t.showStep == _t.lastPage,
        hover: _t.showStep != _t.lastPage,
        'hover--big': _t.showStep != _t.lastPage,
      };
    },
    navigationToggle() {
      let _t = this;

      _t.openNavigationMenu = !_t.openNavigationMenu
      _t.$emit('toggleContactCenterMobile');
    },
    closePresentation() {
      let _t = this;

      for (let i in _t.steps[1].data.list) {
        _t.steps[1].data.list[i].hover = false;
      }

      _t.$store.commit('toggleHamburger', false);
      _t.$store.commit('togglePresentationClose', false);
    }
  }
}
</script>
