<template>
  <div class="page page--action animate__slow" :class="{ loaded: isLoaded }">

    <div class="detail">
      <router-link :to="{name: 'Actions'}" class="detail__close m100"><svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" fill="#FFF" d="M76.684 2.437L40.621 38.5l36.063 36.062-2.122 2.122L38.5 40.621 2.438 76.684.316 74.562 36.379 38.5.316 2.437 2.437.316 38.5 36.379 74.563.316l2.121 2.121z"/></svg></router-link>
      <div class="detail__img" :style="action.imgStyle"></div>

      <perfect-scrollbar class="detail__content m25" ref="ps" v-if="!isMobile">
        <div class="detail__name title-magic"><span>{{ action.title }}</span></div>

        <div class="detail__block" v-if="action.textFull" v-html="action.textFull"></div>

        <div class="detail__btns">
          <span class="btn" @click="$emit('openModalCenter')"><span>{{ get('btnWant') }}</span></span>
        </div>
      </perfect-scrollbar>
      <div class="detail__content m25" v-else>
        <div class="detail__name title-magic"><span>{{ action.title }}</span></div>

        <div class="detail__block" v-if="action.textFull" v-html="action.textFull"></div>

        <div class="detail__btns">
          <span class="btn" @click="$emit('openModalCenter')"><span>{{ get('btnWant') }}</span></span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Action',
  mixins: [loader, isMobile, functions],
  emits: ['loaded', 'openModalCenter'],

  props: ['id'],

  data() {
    return {
      pageData: {page: 'Action'},
      action: {}
    }
  },

  unmounted() {
    this.$store.commit('changeLogoClass', false);
  },

  mounted() {
    var _t = this;

    _t.$store.commit('loaderON');

    _t.$store.subscribe((mutation, state) => {
      if (mutation.type === 'pageLoaded' && mutation.payload.page == _t.pageData.page) {
        _t.onActivated();
      }
    });
  },

  watch: {
    imagesLoaded(val) {
      var _t = this;
      if (_t.magicComplete && val) {
        _t.pageLoaded();
      }
    },
    magicComplete(val) {
      var _t = this;
      if (_t.imagesLoaded && val) {
        _t.pageLoaded();
      }
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.action = _t.get('action');

      _t.$store.commit('changeLogoClass', true);

      if (_t.imagesLoaded && _t.magicComplete) {
        _t.pageLoaded();
      } else {
        var images = [];

        _t.action.imgStyle = _t.bgImgStyle(_t.action.img);

        for (let j in _t.action.img) {
          images.push(_t.action.img[j]);
        }

        _t.loadImages(images);
        _t.magic();
      }
    },
  }
}
</script>
