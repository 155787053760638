<template>
  <div class="page page--service animate__slow" :class="{ loaded: isLoaded }">

    <div class="detail">
      <router-link :to="{name: 'Services'}" class="detail__close m100"><svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" fill="#FFF" d="M76.684 2.437L40.621 38.5l36.063 36.062-2.122 2.122L38.5 40.621 2.438 76.684.316 74.562 36.379 38.5.316 2.437 2.437.316 38.5 36.379 74.563.316l2.121 2.121z"/></svg></router-link>
      <div class="detail__img" :style="service.imgStyle"></div>

      <perfect-scrollbar class="detail__content m25" ref="ps" v-if="!isMobile">
        <router-link :to="{name: 'Services'}" class="link link--reverse">{{ get('btnBack') }}</router-link>

        <h1 class="detail__name detail__name--big detail__name--mobile-small title-magic"><span>{{ service.title }}</span></h1>

        <div class="breadcrumbs" v-if="!isMobile">
          <template v-for="breadcrumb in get('breadcrumbs')">
            <span v-if="breadcrumb.route == false" class="breadcrumb" v-html="breadcrumb.title"></span>
            <router-link :to="{name: breadcrumb.route, params: {sectionId: breadcrumb.id}}" class="breadcrumb" v-if="breadcrumb.route != false"><span class="link link--reverse" v-html="breadcrumb.title"></span></router-link>
          </template>
        </div>

        <div class="blocks">
          <template v-for="(block, id) in blocks">
            <div class="detail__block wow-animate animate__slow" wow-class="animate__fadeInUpSmall">
              <div class="title title--tiny">{{ block.title }}</div>

              <template v-if="block.type === 'tabs'">
                <div class="tabs-outer">
                  <div class="tabs-block-2">
                    <swiper class="tabs-block" v-if="block.tabs.length > 0"
                            :slides-per-view="swiperTabsOptions.slidesPerView"
                            :free-mode="swiperTabsOptions.freeMode"
                            :scrollbar="{ draggable: true }"
                    >
                      <swiper-slide class="tabs">
                        <div class="tab" v-for="(tab, index) in block.tabs" :class="{active: block.tabIndex == index, link: isMobile}" @click="changeTab(block, index, id)">{{ tab.tab }}</div>
                      </swiper-slide>
                    </swiper>
                  </div>

                  <div class="tabs-content" :class="block.tabsContentClass">
                    <div class="tab-content text" v-for="(tab, index) in block.tabs" :class="{active: block.tabIndex == index, 'text--sm': !isMobile, 'text--md': isMobile}">
                      <div class="tab-content-inner active" v-html="tab.text"></div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="block.type === 'text_pic'">
                <div class="block block--pic text text--md">
                  <div class="block__pic" :style="block.imgStyle"></div>
                  <div class="block__text" v-html="block.text"></div>
                </div>
              </template>
              <template v-else-if="block.type === 'gallery'">
                <div class="slider-detail-outer">
                  <swiper class="slider-detail slider-images" v-if="block.photos.length > 0"
                          :slides-per-view="swiperOptions.slidesPerView"
                          :speed="swiperOptions.speed"
                          :navigation="{nextEl: '.slider-next-' + id, prevEl: '.slider-prev-' + id}"
                          @swiper="(swiper) => {block.swiper = swiper}"
                          @slideChange="block.slider.index = block.swiper.activeIndex"
                  >
                    <swiper-slide v-for="(slide, index) in block.photos">
                      <a :href="slide.imgHref" class="image" :data-fancybox="'images' + id" :class="{last: index == block.photos.length - 1}" :style="slide.imgStyle"></a>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="slider-navigation">
                  <div class="slider-prev hover hover--big" :class="block.slider.prevClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>

                  <div class="slider-pages">
                    <span class="slider-pages__current">{{ block.slider.index+1 }}</span>
                    &nbsp;<span>{{ get('textFrom') }}</span>&nbsp;
                    <span class="slider-pages__all" v-if="!isMobile">{{ $tc('slide', block.photos.length) }}</span>
                    <span class="slider-pages__all" v-else>{{ block.photos.length }}</span>
                  </div>

                  <div class="slider-next hover hover--big" :class="block.slider.nextClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>
                </div>
              </template>
              <template v-else-if="block.type === 'table'">
                <div class="table">
                  <div class="table-head" v-if="block.table.head && !isMobile">
                    <div v-for="item in block.table.head">{{ item }}</div>
                  </div>
                  <div class="table-row" v-for="row in block.table.rows" :class="{hidden: row.show == false}">
                    <div v-for="item in row">{{ item }}</div>
                  </div>
                </div>

                <div class="btns btns--3" v-if="block.showMore">
                  <span class="btn btn--xs" @click="showMore(block)"><span>{{ get('btnMore') }}</span></span>
                </div>
              </template>
              <template v-else-if="block.type === 'faq'">
                <div class="faq">
                  <div class="question" v-for="question in block.faq">
                    <div class="question__title hover" onclick="$(this).closest('.question').toggleClass('active').find('.question__answer').slideToggle()"><div>{{ question.question }}</div></div>
                    <div class="question__answer" v-html="question.answer"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="block.type === 'items'">
                <div class="slider-detail-outer">
                  <swiper class="slider-detail" v-if="block.items.length > 0"
                          :slides-per-view="swiperOptions.slidesPerView"
                          :speed="swiperOptions.speed"
                          :navigation="{nextEl: '.slider-next-' + id, prevEl: '.slider-prev-' + id}"
                          @swiper="(swiper) => {block.swiper = swiper}"
                          @slideChange="block.slider.index = block.swiper.activeIndex"
                  >
                    <swiper-slide v-for="(slide, index) in block.items">
                      <router-link :to="{ name: 'Medicine', params: { id: slide.id }}" class="doctor hover" :class="{last: index == block.items.length - 1}">
                        <div class="doctor__pic"><div class="doctor__pic-inner" :style="slide.imgStyle"></div></div>
                        <div class="doctor__content">
                          <div class="doctor__name">{{ slide.title }}</div>
                        </div>
                      </router-link>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="slider-navigation">
                  <div class="slider-prev hover hover--big" :class="block.slider.prevClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>

                  <div class="slider-pages">
                    <span class="slider-pages__current">{{ block.slider.index+1 }}</span>
                    &nbsp;<span>{{ get('textFrom') }}</span>&nbsp;
                    <span class="slider-pages__all">{{ $tc('slide', block.items.length) }}</span>
                  </div>

                  <div class="slider-next hover hover--big" :class="block.slider.nextClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>
                </div>
              </template>
              <div v-else class="text text--md" v-html="block.text"></div>

            </div>
          </template>
        </div>
      </perfect-scrollbar>
      <div class="detail__content m25" v-else>
        <router-link :to="{name: 'Services'}" class="link link--reverse">{{ get('btnBack') }}</router-link>

        <h1 class="detail__name detail__name--big detail__name--mobile-small title-magic"><span>{{ service.title }}</span></h1>

        <div class="breadcrumbs" v-if="!isMobile">
          <template v-for="breadcrumb in get('breadcrumbs')">
            <span v-if="breadcrumb.route == false" class="breadcrumb" v-html="breadcrumb.title"></span>
            <router-link :to="{name: breadcrumb.route, params: {sectionId: breadcrumb.id}}" class="breadcrumb" v-if="breadcrumb.route != false"><span class="link link--reverse" v-html="breadcrumb.title"></span></router-link>
          </template>
        </div>

        <div class="blocks">
          <template v-for="(block, id) in blocks">
            <div class="detail__block wow-animate animate__slow" wow-class="animate__fadeInUpSmall">
              <div class="title title--tiny">{{ block.title }}</div>

              <template v-if="block.type === 'tabs'">
                <div class="tabs-outer">
                  <div class="tabs-block-2">
                    <swiper class="tabs-block" v-if="block.tabs.length > 0"
                            :slides-per-view="swiperTabsOptions.slidesPerView"
                            :free-mode="swiperTabsOptions.freeMode"
                            :scrollbar="{ draggable: true }"
                    >
                      <swiper-slide class="tabs">
                        <div class="tab" v-for="(tab, index) in block.tabs" :class="{active: block.tabIndex == index, link: isMobile}" @click="changeTab(block, index, id)">{{ tab.tab }}</div>
                      </swiper-slide>
                    </swiper>
                  </div>

                  <div class="tabs-content" :class="block.tabsContentClass">
                    <div class="tab-content text" v-for="(tab, index) in block.tabs" :class="{active: block.tabIndex == index, 'text--sm': !isMobile, 'text--md': isMobile}">
                      <div class="tab-content-inner active" v-html="tab.text"></div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="block.type === 'text_pic'">
                <div class="block block--pic text text--md">
                  <div class="block__pic" :style="block.imgStyle"></div>
                  <div class="block__text" v-html="block.text"></div>
                </div>
              </template>
              <template v-else-if="block.type === 'gallery'">
                <div class="slider-detail-outer">
                  <swiper class="slider-detail slider-images" v-if="block.photos.length > 0"
                          :slides-per-view="swiperOptions.slidesPerView"
                          :speed="swiperOptions.speed"
                          :navigation="{nextEl: '.slider-next-' + id, prevEl: '.slider-prev-' + id}"
                          @swiper="(swiper) => {block.swiper = swiper}"
                          @slideChange="block.slider.index = block.swiper.activeIndex"
                  >
                    <swiper-slide v-for="(slide, index) in block.photos">
                      <a :href="slide.imgHref" class="image" :data-fancybox="'images' + id" :class="{last: index == block.photos.length - 1}" :style="slide.imgStyle"></a>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="slider-navigation">
                  <div class="slider-prev hover hover--big" :class="block.slider.prevClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>

                  <div class="slider-pages">
                    <span class="slider-pages__current">{{ block.slider.index+1 }}</span>
                    &nbsp;<span>{{ get('textFrom') }}</span>&nbsp;
                    <span class="slider-pages__all" v-if="!isMobile">{{ $tc('slide', block.photos.length) }}</span>
                    <span class="slider-pages__all" v-else>{{ block.photos.length }}</span>
                  </div>

                  <div class="slider-next hover hover--big" :class="block.slider.nextClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>
                </div>
              </template>
              <template v-else-if="block.type === 'table'">
                <div class="table">
                  <div class="table-head" v-if="block.table.head && !isMobile">
                    <div v-for="item in block.table.head">{{ item }}</div>
                  </div>
                  <div class="table-row" v-for="row in block.table.rows" :class="{hidden: row.show == false}">
                    <div v-for="item in row">{{ item }}</div>
                  </div>
                </div>

                <div class="btns btns--3" v-if="block.showMore">
                  <span class="btn btn--xs" @click="showMore(block)"><span>{{ get('btnMore') }}</span></span>
                </div>
              </template>
              <template v-else-if="block.type === 'faq'">
                <div class="faq">
                  <div class="question" v-for="question in block.faq">
                    <div class="question__title hover" onclick="$(this).closest('.question').toggleClass('active').find('.question__answer').slideToggle()"><div>{{ question.question }}</div></div>
                    <div class="question__answer" v-html="question.answer"></div>
                  </div>
                </div>
              </template>
              <template v-else-if="block.type === 'items'">
                <div class="slider-detail-outer">
                  <swiper class="slider-detail" v-if="block.items.length > 0"
                          :slides-per-view="swiperOptions.slidesPerView"
                          :speed="swiperOptions.speed"
                          :navigation="{nextEl: '.slider-next-' + id, prevEl: '.slider-prev-' + id}"
                          @swiper="(swiper) => {block.swiper = swiper}"
                          @slideChange="block.slider.index = block.swiper.activeIndex"
                  >
                    <swiper-slide v-for="(slide, index) in block.items">
                      <router-link :to="{ name: 'Medicine', params: { id: slide.id }}" class="doctor hover" :class="{last: index == block.items.length - 1}">
                        <div class="doctor__pic"><div class="doctor__pic-inner" :style="slide.imgStyle"></div></div>
                        <div class="doctor__content">
                          <div class="doctor__name">{{ slide.title }}</div>
                        </div>
                      </router-link>
                    </swiper-slide>
                  </swiper>
                </div>

                <div class="slider-navigation">
                  <div class="slider-prev hover hover--big" :class="block.slider.prevClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>

                  <div class="slider-pages">
                    <span class="slider-pages__current">{{ block.slider.index+1 }}</span>
                    &nbsp;<span>{{ get('textFrom') }}</span>&nbsp;
                    <span class="slider-pages__all">{{ $tc('slide', block.items.length) }}</span>
                  </div>

                  <div class="slider-next hover hover--big" :class="block.slider.nextClass"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></div>
                </div>
              </template>
              <div v-else class="text text--md" v-html="block.text"></div>

            </div>
          </template>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Service',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],
  components: {
    Swiper,
    SwiperSlide
  },

  props: ['id'],

  data() {
    return {
      pageData: {page: 'Service'},
      service: {},
      blocks: {},
      swiperTabsOptions: {
        slidesPerView: 'auto',
        freeMode: true,
      },
      swiperOptions: {
        slidesPerView: 'auto',
        speed: 1000,
      },
      viewRowsCnt: 5,
      viewRowsCntMobile: 3
    }
  },

  unmounted() {
    this.$store.commit('changeLogoClass', false);
    this.$store.commit('changeBtnContactCenterPosition');
    this.$store.commit('changeBtnContactCenterClass');
  },

  mounted() {
    var _t = this;

    _t.$store.commit('loaderON');

    _t.$store.subscribe((mutation, state) => {
      if (mutation.type === 'pageLoaded' && mutation.payload.page == _t.pageData.page) {
        _t.onActivated();
      }
    });
  },

  watch: {
    imagesLoaded(val) {
      var _t = this;
      if (_t.magicComplete && val) {
        _t.pageLoaded();
      }
    },
    magicComplete(val) {
      var _t = this;
      if (_t.imagesLoaded && val) {
        _t.pageLoaded();
      }
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.service = _t.get('service');
      _t.blocks = _t.service.blocks;

      _t.$store.commit('changeBtnContactCenterPosition', 'bottom');
      _t.$store.commit('changeBtnContactCenterClass', 'btn--md');
      _t.$store.commit('changeLogoClass', true);

      if (_t.imagesLoaded && _t.magicComplete) {
        _t.pageLoaded();
      } else {
        var images = [];

        _t.service.imgStyle = _t.bgImgStyle(_t.service.img);

        for (let j in _t.service.img) {
          images.push(_t.service.img[j]);
        }

        //перебираем блоки
        for (let i in _t.blocks) {
          switch (_t.blocks[i].type) {
            case 'tabs':
              _t.blocks[i].tabIndex = 0;
              _t.blocks[i].tabsContentClass = {};
              _t.blocks[i].tabsContentClass['tabs-content--' + i] = true;
              break;
            case 'table':
              _t.blocks[i].showMore = _t.blocks[i].table.rows.length > _t.viewRowsCnt;

              let cnt = _t.viewRowsCnt;
              if (_t.isMobile) {
                cnt = _t.viewRowsCntMobile
              }

              for (let j in _t.blocks[i].table.rows) {
                _t.blocks[i].table.rows[j].show = cnt-- > 0;
              }
              break;

            //images
            case 'text_pic':
              _t.blocks[i].imgStyle = _t.bgImgStyle(_t.blocks[i].img);

              for (let j in _t.blocks[i].img) {
                images.push(_t.blocks[i].img[j]);
              }
              break;
            case 'gallery':
              _t.blocks[i].slider = {
                index: 0,
                nextClass: {},
                prevClass: {},
              };

              _t.blocks[i].slider.nextClass['slider-next-' + i] = true
              _t.blocks[i].slider.prevClass['slider-prev-' + i] = true


              for (let x in _t.blocks[i].photos) {
                _t.blocks[i].photos[x].imgStyle = _t.bgImgStyle(_t.blocks[i].photos[x].img);

                for (let j in _t.blocks[i].photos[x].img) {
                  images.push(_t.blocks[i].photos[x].img[j]);
                  _t.blocks[i].photos[x].imgHref = _t.blocks[i].photos[x].img[j];
                }
              }
              break;
            case 'items':
              _t.blocks[i].slider = {
                index: 0,
                nextClass: {},
                prevClass: {},
              };

              _t.blocks[i].slider.nextClass['slider-next-' + i] = true
              _t.blocks[i].slider.prevClass['slider-prev-' + i] = true

              for (let x in _t.blocks[i].items) {
                _t.blocks[i].items[x].imgStyle = _t.bgImgStyle(_t.blocks[i].items[x].img);

                for (let j in _t.blocks[i].items[x].img) {
                  images.push(_t.blocks[i].items[x].img[j]);
                }
              }
              break;
          }
        }

        _t.loadImages(images);
        _t.magic();
      }
    },
    showMore(block) {
      let _t = this;
      let cnt = _t.viewRowsCnt;
      let showMore = false;

      for (let j in block.table.rows) {
        block.table.rows[j].show = block.table.rows[j].show || !block.table.rows[j].show && cnt-- > 0;

        if (!block.table.rows[j].show) {
          showMore = true;
        }
      }

      block.showMore = showMore;
    },
    changeTab(block, index, id) {
      let tabContent = $('.tabs-content--' + id);
      tabContent.height($('.tab-content.active .tab-content-inner', tabContent).height());
      $('.tab-content-inner', tabContent).removeClass('active');

      setTimeout(function () {
        block.tabIndex = index;

        setTimeout(function () {
          tabContent.height($('.tab-content.active .tab-content-inner', tabContent).height());
          $('.tab-content.active .tab-content-inner', tabContent).addClass('active');
        }, 100);
      }, 600);
    }
  }
}
</script>
