export const functions = {
    methods: {
        get(val) {
            let _t = this;

            let page = 'App';

            if (_t.pageData && _t.pageData.page) {
                page = _t.pageData.page;
            }

            if (_t.$store.state.pages[page] && _t.$store.state.pages[page][val]) {
                return _t.$store.state.pages[page][val];
            }

            //значения из базового шаблона
            page = 'App';

            if (_t.$store.state.pages[page] && _t.$store.state.pages[page][val]) {
                return _t.$store.state.pages[page][val];
            }

            //return val;
            return '';
        },
        bgImgStyle: function(images){
            let stages = [];

            for (let i in images) {
                stages.push("url("+ images[i] +") " + i);
            }

            let str = "image-set(" + stages.join(',') + ")";

            return {
                "background-image": ['-webkit-' + str, str]
            }
        },
        randomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        fancy(href) {
            $.fancybox.close();
            $.fancybox.open({
                src : href,
            });
        },
        wowAnimate(root = false) {
            let _t = this;

            setTimeout(function () {
                $('.wow-animate').each(function() {
                    let _el = $(this);

                    if(_el.data('wow')) {
                        return
                    }

                    _el.addClass('animate__animated');

                    _t.observeScroll(_el[0], _el.attr('wow-class'), root);

                    _el.data('wow', 1)
                });
            }, 1500);
        },
        observeScroll(target, targetActiveClass, root = false) {
            // Find the item we want to animate on scroll
            //var target = document.querySelector(targetHash);
            //var targetActiveClass = 'animate__animated';

            // Call this function when it enters/leaves the viewport
            var callback = function(entries, observer) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(targetActiveClass);
                    } else {
                        //entry.target.classList.remove(targetActiveClass);
                    }
                });
            };

            root = root || '#wow';

            root = this.isMobile ? '#wrapper > .page' : root;

            // Create our observer
            var observer = new IntersectionObserver(callback, {
                threshold: 0,
                root: document.querySelector(root),
            });
            observer.observe(target);
        },
        reverseAnimation() {
            let _t = this;
            _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile, rightOut: true});
        },
        sendForm(type = '', fromModal = true) {
            let _t = this;

            if (_t.formInAction) {
                return false;
            }

            _t.formInAction = true

            type = type ? type : _t.formType;
            let form = _t.get(type)

            for (let i in form) {
                form[i].value = _t.formElems[type][i]
            }

            let params = {
                action: type,
                form: form
            }

            console.log(params)

            _t.$store.dispatch('sendForm', params);

            _t.$store.subscribe((mutation, state) => {
                if (mutation.type === 'formResponse') {
                    _t.formInAction = false

                    if (_t.$store.state.formResponseData.success) {

                        _t.$store.commit('changeModal', {
                            modalHeader: _t.get('modalHeaderSuccess'),
                            modalText: _t.get(type + 'TextSuccess'),
                        });

                        if (fromModal) {
                            _t.modalShowed = false;
                        }

                        for (let i in form) {
                            _t.formElems[i] = ''
                        }
                    } else {
                        _t.$store.commit('changeModal', {
                            modalHeader: _t.get('modalHeaderError'),
                            modalText: _t.get(type + 'TextError'),
                        });
                    }

                    _t.$emit('openModal')
                }
            });
        },
        magic: function (attempt) {
            var _t = this;

            if (!_t.isMobile) {
                $('.title-magic').removeAttr('style');
                _t.magicComplete = true;
                return;
            }

            if (!attempt) {
                attempt = 0;

                $('.title-magic').removeAttr('style');
            }

            _t.$nextTick(function () {
                let _t = this;

                setTimeout(function () {
                    let blockWidth = $('.title-magic:last').width();
                    let articlesWidth = $('.title-magic:last > span').width();
                    let articleFontSize = parseInt($('.title-magic:last').css('font-size')) * 100 / $(window).width();
                    let articleLineHeight = parseInt($('.title-magic:last').css('line-height')) * 100 / $(window).width();

                    //console.log(articlesWidth, blockWidth, attempt);

                    if (articlesWidth >= blockWidth && attempt < 50) {
                        $('.title-magic').css({
                            'font-size': (articleFontSize - 0.2) + 'vw',
                            'line-height': (articleLineHeight - 0.2) + 'vw',
                        });

                        //check it, анимация длится 0,3 секунды
                        _t.magic(++attempt);

                    } else {
                        _t.magicComplete = true;
                    }
                }, 50);
            });
        },

        calcDepthLevel(sections, parentId, depthLevel) {
            let _t = this;
            let depthLevelFL = false;

            for (let i in sections) {
                sections[i].depthLevel = depthLevel;
                sections[i].parentId = parentId;

                if (sections[i].children) {
                    _t.depthLevelMax += depthLevelFL ? 0 : 1;
                    depthLevelFL = true;

                    _t.calcDepthLevel(sections[i].children, sections[i].id, depthLevel + 1);
                }
            }
        },
        findSection (sections, sectionId) {
            let _t = this;

            let sectionFound = false;

            for (let i in sections) {
                if (sections[i].type == 'section') {
                    if (sections[i].id == sectionId) {
                        return sections[i];
                    } else if (sections[i].children){
                        sectionFound = _t.findSection(sections[i].children, sectionId);

                        if (sectionFound) {
                            return sectionFound;
                        }
                    }
                }
            }

            return false;
        },
        findParentSection (sections) {
            let _t = this;

            let parentId = _t.section.parentId;
            let sectionFound = false;

            for (let i in sections) {
                if (sections[i].type == 'section') {
                    if (sections[i].id == parentId) {
                        return sections[i];
                    } else if (sections[i].children) {
                        sectionFound = _t.findParentSection(sections[i].children);

                        if (sectionFound) {
                            return sectionFound;
                        }
                    }
                }
            }

            return {};
        },
        selectSection(section, routeName = 'Service', withImages = false) {
            let _t = this;

            _t.sectionLoaded = false;

            if (section == -1) {
                _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile, rightOut: true});

                _t.section = _t.findParentSection(_t.sections);

                setTimeout(function () {
                    _t.sectionLoaded = true;
                    _t.wowAnimate();
                }, 10);
            } else {

                _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile});

                if (section.type == 'section') {
                    _t.section = section;

                    if (withImages) {
                        var images = [];

                        for (let i in _t.section['children']) {
                            _t.section['children'][i].imgStyle = _t.bgImgStyle(_t.section['children'][i].img);

                            /*for (let j in _t.section['children'][i].img) {
                                images.push(_t.section['children'][i].img[j]);
                            }*/
                        }

                        //_t.loadImages(images);
                    }

                    setTimeout(function () {
                        _t.sectionLoaded = true;
                        _t.wowAnimate();
                    }, 10);
                } else {
                    _t.$router.push({name: routeName, params: {sectionId: section.parent, id: section.id}});
                }

            }
        }
    }
};
