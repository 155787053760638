<template>
  <div class="page page--actions animate__slow" :class="{ loaded: isLoaded }">

    <template v-if="!isMobile">
      <div class="slider-pages m100">
        <span class="slider-pages__current">{{ sliderIndex+1 }}</span>/<span class="slider-pages__all">{{ actions.length }}</span>
      </div>

      <div class="title title--big m25">{{ get('title') }}</div>

      <div class="slider-outer">
        <swiper class="slider-actions" v-if="actions.length > 0"
                :slides-per-view="swiperOptions.slidesPerView"
                :speed="swiperOptions.speed"
                :navigation="{nextEl: '.slider-next', prevEl: '.slider-prev'}"
                @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(slide, index) in actions">
            <router-link :to="{ name: 'Action', params: { id: slide.id }}" class="action" :class="{last: index == actions.length - 1}">
              <div class="action__pic-outer"><div class="action__pic" :style="slide.imgStyle"></div></div>
              <div class="action__content text">
                <perfect-scrollbar class="action__text" ref="ps" v-if="!isMobile">
                  <div class="action__title">{{ slide.title }}</div>
                  <div v-html="slide.text"></div>
                </perfect-scrollbar>
                <div class="action__text" v-else>
                  <div class="action__title">{{ slide.title }}</div>
                  <div v-html="slide.text"></div>
                </div>

                <div class="action__period">{{ slide.period }}</div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>

      <div class="slider-prev hover hover--big hover--arrow-prev" :style="$store.state.sliderPrevStyle"></div>
      <div class="slider-next hover hover--big hover--arrow-next" :style="$store.state.sliderNextStyle"></div>
    </template>
    <template v-else>
      <router-link v-for="(slide, index) in actions" :to="{ name: 'Action', params: { id: slide.id }}" class="action">
        <div class="action__pic-outer"><div class="action__pic" :style="slide.imgStyle"></div></div>
        <div class="action__content text">
          <div class="action__text">
            <div class="action__title">{{ slide.title }}</div>
            <div v-html="slide.text"></div>
          </div>

          <div class="action__period">{{ slide.period }}</div>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Actions',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      pageData: {page: 'Actions'},
      actions: [],
      swiperOptions: {
        slidesPerView: 'auto',
        speed: 1500,
      },
      sliderIndex: 0,
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.actions = _t.get('actions');

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        var images = [];

        for (let i in _t.actions) {
          _t.actions[i].imgStyle = _t.bgImgStyle(_t.actions[i].img);

          for (let j in _t.actions[i].img) {
            images.push(_t.actions[i].img[j]);
          }
        }

        _t.loadImages(images);
      }
    },
    onSlideChange(swiper) {
      this.sliderIndex = swiper.activeIndex
    },
  }
}
</script>
