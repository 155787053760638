<template>
  <div class="page page--contacts page--flex animate__slow" :class="{ loaded: isLoaded }">

    <div class="page__map">
      <GoogleMap class="map" v-if="map.googleMapsApiKey"
                 :apiKey="map.googleMapsApiKey"
                 :center="map.center"
                 :zoom="map.zoom"
                 :minZoom="9"
                 :styles="mapStyles"
                 :fullscreenControl="false"
                 :mapTypeControl="false"
                 :streetViewControl="false"
                 :zoomControl="false"
      >
        <Marker :options="isMobile ? map.markerMobile : map.marker" />
      </GoogleMap>
    </div>
    <div class="page__content m25">
      <div>
        <div class="title" :class="{'title--big': !isMobile, 'title--mobile-small': isMobile}">{{ get('title') }}</div>
        <div class="text" v-html="get('text')"></div>

        <div class="address-block">
          <div class="address">
            <span class="link-2 link-2--reverse" @click="copyTextToClipboard(get('address'))" v-html="get('address')"></span>
            <span class="address-copy" :class="{active: copyShow}">{{ get('textCopy') }}</span>
          </div>
          <div class="working-hours">{{ get('workingHours') }}</div>
          <div class="phone"><a class="link link--reverse" :href="get('tabCallPhoneLink')"><b>{{ get('tabCallPhone') }}</b></a></div>
        </div>
        <div class="btns btns--2">
          <template v-for="btn in btns">
            <a :href="btn.link" target="_blank" v-if="!btn.fancy" class="btn btn--fixed" :class="{'btn--sm': !isMobile, 'btn--xs': isMobile}"><span>{{ btn.title }}</span></a>
            <span @click="fancy(btn.link)" v-if="btn.fancy" class="btn btn--fixed" :class="{'btn--sm': !isMobile, 'btn--xs': isMobile}"><span>{{ btn.title }}</span></span>
          </template>
        </div>
      </div>
      <div class="page__footer" v-if="!isMobile">
        <router-link :to="{ name: 'Promo'}" class="logo logo--big"><img src="../assets/images/logo.svg"></router-link>
        <span class="btn btn--md btn--md-2" @click="$emit('openModalCenter')"><span>{{ get('btnContactCenter') }}</span></span>
      </div>
    </div>

  </div>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Contacts',
  mixins: [loader, isMobile, functions],
  emits: ['loaded', 'openModalCenter'],
  components: { GoogleMap, Marker },

  data() {
    return {
      pageData: {page: 'Contacts'},
      map: {},
      mapStyles: [
        {
          "featureType": "all",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#ffffff"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "weight": "3"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "weight": 1
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "weight": "1"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ],
      btns: [],
      copyShow: false
    }
  },

  deactivated() {
    this.$store.commit('changeBtnContactCenterPosition');
    this.$store.commit('changeLogoState', false);
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.map = _t.get('map');
      _t.btns = _t.get('btns');

      _t.$store.commit('changeBtnContactCenterPosition', -1);
      _t.$store.commit('changeLogoState', true);

      _t.pageLoaded();
    },
    copyTextToClipboard (value) {
      let _t = this;

      const textarea = document.createElement('textarea')

      textarea.value = value
      textarea.setAttribute('readonly', '')
      textarea.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;'

      document.body.appendChild(textarea)

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        textarea.contentEditable = true
        textarea.readOnly = true

        const range = document.createRange()

        range.selectNodeContents(textarea)

        const selection = window.getSelection()

        selection.removeAllRanges()
        selection.addRange(range)
        textarea.setSelectionRange(0, 999999)
      } else {
        textarea.select()
      }

      let success = false

      try {
        success = document.execCommand('copy')

        _t.copyShow = true;
        setTimeout(function() {
          _t.copyShow = false;
        }, 5000);
      } catch (err) {
        console.warn(err)
      }

      document.body.removeChild(textarea)
    },
  }
}
</script>
