<template>
  <div class="page page--services page--flex animate__slow">

    <template v-for="index in depthLevelMax" :key="index">
      <transition :enter-active-class="$store.state.enterClassName" :leave-active-class="$store.state.leaveClassName">
        <div class="page page--services page--flex" v-if="section.depthLevel == index" :class="{ loaded: sectionLoaded, 'page--services-inner': index > 1 }">

          <div class="page__col">
            <div>
              <div class="back" v-if="index > 1 && isMobile">
                <span class="link link--reverse" @click="selectSection(-1)"><span>{{ get('btnBack') }}</span></span>
              </div>

              <div class="title" :class="{
                m50: isMobile,
                'title--small': isMobile && index == 1,
                'title--xs': isMobile && index > 1,
              }" v-html="section.title"></div>

              <div class="btns" v-if="index > 1 && !isMobile">
                <span class="btn btn--sm" @click="selectSection(-1)"><span>{{ get('btnBack') }}</span></span>
              </div>
            </div>
          </div>
          <perfect-scrollbar class="page__content" ref="ps" v-if="!isMobile">
            <div class="sections">
              <div class="section hover" v-for="(section, id) in section.children" :class="{'section--small': index > 1, 'wow-animate': !isMobile}" wow-class="animate__fadeInUp">
                <div class="section__title link-2" @click="selectSection(section)">
                  <span v-html="section.title"></span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div class="page__content" v-else>
            <div class="sections">
              <div class="section hover" v-for="(section, id) in section.children" :class="{'section--small': index > 1, 'wow-animate': !isMobile}" wow-class="animate__fadeInUp">
                <div class="section__title link-2" @click="selectSection(section)">
                  <span v-html="section.title"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </transition>
    </template>
  </div>

</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Services',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],

  props: ['sectionId'],

  data() {
    return {
      pageData: {page: 'Services'},
      sections: [],
      depthLevelMax: 2,
      sectionLoaded: false,
      section: {}
    }
  },

  deactivated() {
    let _t = this;

    //_t.sectionLoaded = false;
    console.log('deactiveated')
    _t.$store.commit('changeTransitionEffects', {isMobile: _t.isMobile});
  },

  methods: {
    onActivated(){
      var _t = this;

      if (_t.sections.length == 0) {
        _t.sections = _t.get('servicesSections');

        _t.section = _t.sections[0];
        _t.section.depthLevel = 1;

        _t.calcDepthLevel(_t.section.children, _t.section.id, 2);

        if (_t.sectionId) {
          let sectionInit = _t.findSection(_t.sections, _t.sectionId);

          if (sectionInit) {
            _t.selectSection(sectionInit);
          }
        }
      }

      setTimeout(function () {
        _t.sectionLoaded = true;

        _t.pageLoaded();
      }, 100);
    },
  }
}
</script>
