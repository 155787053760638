import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import Promo from '@/components/Promo.vue';
import Doctors from '@/components/Doctors.vue';
import Doctor from '@/components/Doctor.vue';
import Reviews from '@/components/Reviews.vue';
import Info from '@/components/Info.vue';
import Equipment from '@/components/Equipment.vue';
import EquipmentItem from '@/components/EquipmentItem.vue';
import Medicines from '@/components/Medicines.vue';
import Medicine from '@/components/Medicine.vue';
import Contacts from '@/components/Contacts.vue';
import Actions from '@/components/Actions.vue';
import Action from '@/components/Action.vue';
import Services from '@/components/Services.vue';
import Service from '@/components/Service.vue';

const routes = [
    {
      path: '/',
      name: 'Promo',
      component: Promo,
    },
    {
      path: '/reviews',
      name: 'Reviews',
      component: Reviews,
    },
    {
        path: '/info',
        name: 'Info',
        component: Info,
    },
    {
        path: '/doctors',
        name: 'Doctors',
        component: Doctors,
    },
    {
        path: '/doctors/:id',
        name: 'Doctor',
        component: Doctor,
        props: true
    },
    {
        path: '/equipment',
        name: 'Equipment',
        component: Equipment,
    },
    {
        path: '/equipment/:id',
        name: 'EquipmentItem',
        component: EquipmentItem,
        props: true
    },
    {
        path: '/medicines/:sectionId?',
        name: 'Medicines',
        component: Medicines,
        props: true
    },
    {
        path: '/medicine/:sectionId/:id',
        name: 'Medicine',
        component: Medicine,
        props: true
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/actions',
        name: 'Actions',
        component: Actions,
    },
    {
        path: '/actions/:id',
        name: 'Action',
        component: Action,
        props: true
    },
    {
        path: '/services/:sectionId?',
        name: 'Services',
        component: Services,
        props: true
    },
    {
        path: '/services/:sectionId/:id',
        name: 'Service',
        component: Service,
        props: true
    },
    {
      path: "/:catchAll(.*)",
      redirect: {name: 'Promo'}
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    //history: createWebHistory(),
    routes,
})

export default router;
