<template>
  <div class="page page--info page--flex animate__slow" :class="{ loaded: isLoaded }" id="page">

    <div class="page__col">
      <div>
        <div class="title" :class="{'title--small': !isMobile, 'title--mobile-small': isMobile}">{{ get('title') }}</div>
      </div>
    </div>
    <perfect-scrollbar class="page__content m25" @ps-scroll-y="onScrollContent" ref="scrollbar" id="infoContent" v-if="!isMobile">
      <div :id="'info' + index" class="info text" v-for="(item, index) in info">
        <div class="info__title" v-html="item.title"></div>
        <div class="info__text" v-html="item.text"></div>
      </div>
    </perfect-scrollbar>
    <div class="page__content m25" v-else>
      <div :id="'info' + index" class="info text" v-for="(item, index) in info">
        <div class="info__title" v-html="item.title"></div>
        <div class="info__text" v-html="item.text"></div>
      </div>
    </div>

    <perfect-scrollbar class="page__right m75" ref="ps" v-if="!isMobile">
      <div class="info-menu">
        <template v-for="(item, index) in info">
          <div class="info-menu-item" :class="{active: item.active == true}">
            <span v-scroll-to="{el: '#info' + index, container: '#infoContent'}" class="link-2 link-2--reverse hover" v-html="item.title"></span>
          </div>
        </template>
      </div>
    </perfect-scrollbar>
    <template v-else>
      <div class="navigation" :class="{active: openNavigationMenu == true}">
        <div class="navigation__menu navigation__menu--scroll">
          <template v-for="(item, index) in info">
            <div class="navigation__menu-item navigation__menu-item--simple" @click="navigationToggle()" v-scroll-to="{el: '#info' + index, container: '#page', offset: -120}" :class="{active:item.active == true}">{{ item.title }}</div>
          </template>
        </div>

        <div class="navigation__block navigation__block--center">
          <div class="navigation__plus hover hover--big" @click="navigationToggle()" :class="{active: openNavigationMenu == true}"><svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M84.643 44.443H44.501v40.142h-3.797V44.443H.562v-3.797h40.142V.504h3.797v40.142h40.142v3.797z"/></svg></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Info',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],

  data() {
    return {
      pageData: {page: 'Info'},
      info: [],
      openNavigationMenu: false
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.info = _t.get('info');

      _t.info[0].active = true;

      _t.pageLoaded();
    },
    onScrollContent(event) {
      let _t = this;
      let top = _t.$refs.scrollbar.ps.scrollbarYTop;
      let initHeight = parseInt(window.outerHeight / 2);
      let checkLast = true;

      for (let j in _t.info) {
        _t.info[j].active = false;
      }

      for (let i in _t.info) {
        let elemTop = $('#info' + i).position().top;

        if (elemTop >= initHeight && _t.info[i-1]) {
          checkLast = false;
          _t.info[i-1].active = true;
          break;
        }
      }

      if (checkLast) {
        _t.info[_t.info.length - 1].active = true;
      }
    },
    navigationToggle() {
      let _t = this;

      _t.openNavigationMenu = !_t.openNavigationMenu
      _t.$emit('toggleContactCenterMobile');
    },
  }
}
</script>
