<template>
  <modal v-if="modalShowed" class="modal" @close="modalShowed = false">
    <template v-slot:header>
      <div class="modal__header">{{ get('modalHeader') }}</div>
      <span class="modal__close hover rotate-on-hover" @click="modalShowed = false"><svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" fill="#FFF" d="M76.684 2.437L40.621 38.5l36.063 36.062-2.122 2.122L38.5 40.621 2.438 76.684.316 74.562 36.379 38.5.316 2.437 2.437.316 38.5 36.379 74.563.316l2.121 2.121z"/></svg></span>
    </template>
    <template v-slot:body>
      <div class="modal__body">
        <div class="btns" :class="{'btns--mt': tab == false}">
          <span class="btn btn--sm btn--line" :class="{active: tab == 'singup'}" @click="changeTab('singup')"><span>{{ get('btnSingUp') }}</span></span>
          <span class="btn btn--sm btn--line" :class="{active: tab == 'call'}" @click="changeTab('call')"><span>{{ get('btnCall') }}</span></span>
          <span class="btn btn--sm btn--line" :class="{active: tab == 'callme'}" @click="changeTab('callme')"><span>{{ get('btnCallMe') }}</span></span>
        </div>

        <div class="tabs-content tabs-content--modal" :class="{active: tab != false}">
          <div class="tab-content" :class="{active: tab == 'singup'}">
            <div class="tab-content-inner">
              <div class="form">
                <div class="form__row" v-for="(elem, index) in get('formSingUp')">
                  <label class="form-elem" :class="{'form-elem--2': elem.type == 'textarea', focused: elem.focused == true}">
                    <div class="form-elem__title">{{ elem.title }}<span v-if="elem.required">*</span></div>
                    <div class="form-elem__input">
                      <input v-model="formElems['formSingUp'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-if="elem.type != 'textarea' && !elem.mask" @focus="elem.focused = true" @blur="elem.focused = false">
                      <input v-model="formElems['formSingUp'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-else-if="elem.type != 'textarea' && elem.mask" @focus="elem.focused = true" @blur="elem.focused = false" v-maska="'+7 (###) ###-##-##'">
                      <textarea v-else v-model="formElems['formSingUp'][index]" :name="elem.name" :class="elem.class" @focus="elem.focused = true" @blur="elem.focused = false"></textarea>
                    </div>
                  </label>
                </div>
                <div class="form__btns form__btns--2">
                  <div class="legend">{{ get('textRequired') }}</div>
                  <span class="btn btn--sm" :class="{'in-action': formInAction == true}" @click="sendForm('formSingUp')"><span>{{ get('btnSend') }}</span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" :class="{active: tab == 'call'}">
            <div class="tab-content-inner">
              <div class="text" v-if="get('tabCalltext')" v-html="get('tabCalltext')"></div>
              <div class="address-block">
                <div class="address">
                  <span class="link link--reverse" @click="copyTextToClipboard(get('tabCallAddress'))" v-html="get('tabCallAddress')"></span>
                  <span class="address-copy" :class="{active: copyShow}">{{ get('textCopy') }}</span>
                </div>
                <div class="working-hours">{{ get('tabCallWorkingHours') }}</div>
                <div class="phone"><a class="link link--reverse" :href="get('tabCallPhoneLink')">{{ get('tabCallPhone') }}</a></div>
              </div>
            </div>
          </div>
          <div class="tab-content" :class="{active: tab == 'callme'}">
            <div class="tab-content-inner">
              <div class="form">
                <div class="form__row" v-for="(elem, index) in get('formCallMe')">
                  <label class="form-elem" :class="{'form-elem--2': elem.type == 'textarea', focused: elem.focused == true}">
                    <div class="form-elem__title">{{ elem.title }}<span v-if="elem.required">*</span></div>
                    <div class="form-elem__input">
                      <input v-model="formElems['formCallMe'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-if="elem.type != 'textarea' && !elem.mask" @focus="elem.focused = true" @blur="elem.focused = false">
                      <input v-model="formElems['formCallMe'][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-else-if="elem.type != 'textarea' && elem.mask" @focus="elem.focused = true" @blur="elem.focused = false" v-maska="'+7 (###) ###-##-##'">
                      <textarea v-else v-model="formElems['formCallMe'][index]" :name="elem.name" :class="elem.class" @focus="elem.focused = true" @blur="elem.focused = false"></textarea>
                    </div>
                  </label>
                </div>
                <div class="form__btns form__btns--2">
                  <div class="legend">{{ get('textRequired') }}</div>
                  <span class="btn btn--sm" :class="{'in-action': formInAction == true}" @click="sendForm('formCallMe')"><span>{{ get('btnSend') }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal__footer modal__footer--empty"></div>
    </template>
  </modal>
</template>

<script>
import { ref, watch, onUpdated } from 'vue'

import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions.js';

export default {
  name: 'ContactCenter',
  mixins: [isMobile, functions],
  emits: ['openModal'],
  props: {
    pageData: {},
    formType: String
  },
  data() {
    return {
      formElems: {
        formSingUp: [],
        formCallMe: [],
      },
      modalShowed: false,
      formInAction: false
    }
  },

  setup(props, {emit}) {
    let modalShowed = ref(false)
    let tab = ref(false);
    let copyShow = ref(false);

    const showModal = () => {
      modalShowed.value = true
    }

    const changeTab = (value) => {
      let tabContent = $('.tabs-content--modal');
      let tabCurrentHeight = $('.tab-content.active .tab-content-inner', tabContent).height();

      tabContent.height(tabCurrentHeight || 0);
      $('.tab-content-inner', tabContent).removeClass('active');

      setTimeout(function () {
        tab.value = value;

        setTimeout(function () {
          tabContent.height($('.tab-content.active .tab-content-inner', tabContent).height());
          $('.tab-content.active .tab-content-inner', tabContent).addClass('active');
        }, 100);
      }, tabCurrentHeight || 0);
    }

    const copyTextToClipboard = (value) => {
      const textarea = document.createElement('textarea')

      textarea.value = value
      textarea.setAttribute('readonly', '')
      textarea.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;'

      document.body.appendChild(textarea)

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        textarea.contentEditable = true
        textarea.readOnly = true

        const range = document.createRange()

        range.selectNodeContents(textarea)

        const selection = window.getSelection()

        selection.removeAllRanges()
        selection.addRange(range)
        textarea.setSelectionRange(0, 999999)
      } else {
        textarea.select()
      }

      let success = false

      try {
        success = document.execCommand('copy')

        copyShow.value = true;
        setTimeout(function() {
          copyShow.value = false;
        }, 5000);
      } catch (err) {
        console.warn(err)
      }

      document.body.removeChild(textarea)
    }

    onUpdated(() => {
      tab.value = false
    })

    return {
      showModal,
      modalShowed,
      changeTab,
      tab,
      copyShow,
      copyTextToClipboard
    }
  },

  methods: {
    showModal() {
      this.modalShowed = true
    },
  }
}
</script>
