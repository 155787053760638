<template>
  <div class="page page--equipment animate__slow" :class="{ loaded: isLoaded }">

    <template v-if="!isMobile">
      <div class="slider-pages m100">
        <span class="slider-pages__all">{{ equipment.length }}</span>
      </div>

      <div class="slider-outer">
        <swiper class="slider-equipment" v-if="equipment.length > 0"
                :slides-per-view="swiperOptions.slidesPerView"
                :speed="swiperOptions.speed"
                :navigation="{nextEl: '.slider-next', prevEl: '.slider-prev'}"
        >
          <swiper-slide v-for="(slide, index) in equipment">
            <div class="equipment-item" :class="{last: index == equipment.length - 1}">
              <div class="equipment-item__pic" :style="slide.imgStyle"></div>
              <div class="equipment-item__title">{{ slide.title }}</div>
              <perfect-scrollbar class="equipment-item__text text" ref="ps" v-if="!isMobile">
                <div v-html="slide.text"></div>
                <div class="btns">
                  <router-link :to="{ name: 'EquipmentItem', params: { id: slide.id }}" class="btn btn--sm"><span>{{ get('btnMore') }}</span></router-link>
                </div>
              </perfect-scrollbar>
              <div class="equipment-item__text text" v-else>
                <div v-html="slide.text"></div>
                <div class="btns">
                  <router-link :to="{ name: 'EquipmentItem', params: { id: slide.id }}" class="btn btn--sm"><span>{{ get('btnMore') }}</span></router-link>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="title title--xl m25">{{ get('title') }}</div>

      <div class="slider-prev hover hover--big hover--arrow-prev" :style="$store.state.sliderPrevStyle"></div>
      <div class="slider-next hover hover--big hover--arrow-next" :style="$store.state.sliderNextStyle"></div>
    </template>

    <template v-if="isMobile">
      <div class="title title--xs m25">{{ get('title') }}</div>

      <router-link :to="{ name: 'EquipmentItem', params: { id: slide.id }}" class="equipment-item animate__slow wow-animate" v-for="(slide, index) in equipment" wow-class="animate__fadeInUpSmall">
        <div class="equipment-item__pic" :style="slide.imgStyle"></div>
        <div class="equipment-item__title">{{ slide.title }}</div>
      </router-link>
    </template>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Equipment',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      pageData: {page: 'Equipment'},
      equipment: [],
      swiperOptions: {
        slidesPerView: 'auto',
        speed: 1500,
      },
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.equipment = _t.get('equipment');

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        var images = [];

        for (let i in _t.equipment) {
          _t.equipment[i].imgStyle = _t.bgImgStyle(_t.equipment[i].img);

          for (let j in _t.equipment[i].img) {
            images.push(_t.equipment[i].img[j]);
          }
        }

        _t.loadImages(images);
      }
    },
  }
}
</script>
