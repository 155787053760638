<template>
    <div class="modal-overlay">

        <div class="modal-container" v-click-outside="outsideClickClose">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>

        </div>

    </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'

export default {
  name: 'Modal',
  emits: ['close'],
  setup({}, {emit}) {
    var readyModal = ref (false);

    function outsideClickClose() {
      if (readyModal == true) {
        emit('close')
      }
    }

    onMounted(() => {
      /*nextTick(() => {
        // Code that will run only after the
        // entire view has been rendered
        readyModal = true;
      })*/

      setTimeout(function () {
        readyModal = true;
      }, 500);
    });

    return {
      outsideClickClose
    }
  }
}
</script>

<style lang="less">
.modal-overlay {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  &.modal--result{
    z-index: 9998;
  }
}

.modal-wrapper {
  display: flex; align-items: center; justify-content: center;
}

.modal-container {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
  width: 300px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}
</style>
