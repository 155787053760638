<template>
  <div class="page page--doctors page--flex animate__slow" :class="{ loaded: isLoaded }">

    <div class="page__col" :class="{m50: isMobile}">
      <div>
        <div class="title">{{ get('title') }}</div>
        <div class="text" :class="{'text--lg': isMobile}" v-html="get('text')"></div>
      </div>
    </div>
    <perfect-scrollbar class="page__content m25" ref="ps" v-if="!isMobile">
      <div class="doctors">
        <div class="animate__slow wow-animate" wow-class="animate__fadeInUpSmall" v-for="doctor in doctors">
          <router-link :to="{ name: 'Doctor', params: { id: doctor.id }}" class="doctor hover">
            <div class="doctor__pic"><div class="doctor__pic-inner" :style="doctor.imgStyle"></div></div>
            <div class="doctor__content">
              <div class="doctor__name">{{ doctor.name }}</div>
              <div class="doctor__prof">{{ doctor.prof }}</div>
            </div>
          </router-link>
        </div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 2"></div>
      </div>
    </perfect-scrollbar>
    <div class="page__content m25" v-else>
      <div class="doctors">
        <div class="animate__slow wow-animate" wow-class="animate__fadeInUpSmall" v-for="doctor in doctors">
          <router-link :to="{ name: 'Doctor', params: { id: doctor.id }}" class="doctor hover">
            <div class="doctor__pic"><div class="doctor__pic-inner" :style="doctor.imgStyle"></div></div>
            <div class="doctor__content">
              <div class="doctor__name">{{ doctor.name }}</div>
              <div class="doctor__prof">{{ doctor.prof }}</div>
            </div>
          </router-link>
        </div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && doctors.length % 3 == 2"></div>
      </div>
    </div>

  </div>
</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Doctors',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],

  data() {
    return {
      pageData: {page: 'Doctors'},
      doctors: []
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.doctors = _t.get('doctors');

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        var images = [];

        for (let i in _t.doctors) {
          _t.doctors[i].imgStyle = _t.bgImgStyle(_t.doctors[i].img);

          for (let j in _t.doctors[i].img) {
            images.push(_t.doctors[i].img[j]);
          }
        }

        _t.loadImages(images);
      }
    },
  }
}
</script>
