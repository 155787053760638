<template>
  <div class="page page--medicines page--flex animate__slow" :class="{ loaded: isLoaded }">

    <div class="page__col">
      <div>
        <div class="title" :class="{'title--md': !isMobile, 'title--mobile-medium': isMobile}">{{ get('title') }}</div>
        <div class="tabs-outer">

          <div class="tabs-block-2">
            <swiper class="tabs-block" v-if="tabs.length > 0"
                    :slides-per-view="swiperOptions.slidesPerView"
                    :free-mode="swiperOptions.freeMode"
                    :scrollbar="{ draggable: true }"
            >
              <swiper-slide class="tabs">
                <div class="tab" v-for="(tab, index) in tabs" :class="{active: tabIndex == index, link: isMobile}" @click="onChangeTab(tab, index)">{{ tab.tab }}</div>
              </swiper-slide>
            </swiper>
          </div>

          <perfect-scrollbar class="tabs-content tabs-content--fixed" ref="scrollbar" v-if="!isMobile">
            <div class="tab-content text" v-for="(tab, index) in tabs" :class="{active: tabIndex == index}">
              <div class="tab-content-inner active" v-html="tab.text"></div>
            </div>
          </perfect-scrollbar>
          <div class="tabs-content tabs-content--fixed" v-else>
            <div class="tab-content text" v-for="(tab, index) in tabs" :class="{active: tabIndex == index}">
              <div class="tab-content-inner active" v-html="tab.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar class="page__content m25" ref="ps" v-if="!isMobile">
      <div class="doctors-back" :class="{hidden: medicinesType == section.id}">
        <span class="btn btn--sm" v-if="medicinesType != section.id" @click="selectSection(-1)"><span>{{ get('btnBack') }}</span></span>
      </div>

      <div class="doctors" v-if="sectionLoaded">

        <div class="animate__slow wow-animate" wow-class="animate__fadeInUpSmall" v-for="(medicine, id) in section.children">
          <div class="doctor hover" @click="selectSection(medicine, 'Medicine', true)">
            <div class="doctor__pic"><div class="doctor__pic-inner" :style="medicine.imgStyle"></div></div>
            <div class="doctor__content">
              <div class="doctor__name">{{ medicine.title }}</div>
            </div>
          </div>
        </div>

        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 2"></div>
      </div>
    </perfect-scrollbar>
    <div class="page__content m25" v-else>
      <div class="doctors-back" :class="{hidden: medicinesType == section.id}">
        <span class="btn btn--sm" v-if="medicinesType != section.id" @click="selectSection(-1)"><span>{{ get('btnBack') }}</span></span>
      </div>

      <div class="doctors" v-if="sectionLoaded">

        <div class="animate__slow wow-animate" wow-class="animate__fadeInUpSmall" v-for="(medicine, id) in section.children">
          <div class="doctor hover" @click="selectSection(medicine, 'Medicine', true)">
            <div class="doctor__pic"><div class="doctor__pic-inner" :style="medicine.imgStyle"></div></div>
            <div class="doctor__content">
              <div class="doctor__name">{{ medicine.title }}</div>
            </div>
          </div>
        </div>

        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 1"></div>
        <div class="doctor doctor--empty" v-if="!isMobile && section.children && section.children.length % 3 == 2"></div>
      </div>
    </div>

  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Medicines',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],
  components: {
    Swiper,
    SwiperSlide,
  },

  props: ['sectionId'],

  data() {
    return {
      pageData: {page: 'Medicines'},
      medicines: [],
      tabs: [],
      tabIndex: 0,
      medicinesType: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true,
      },

      sections: [],
      depthLevelMax: 2,
      sectionLoaded: false,
      section: {}
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      //_t.medicines = _t.get('medicines');
      _t.tabs = _t.get('tabs');

      _t.medicinesType = _t.tabs[0].type;

      if (_t.sections.length == 0) {
        _t.sections = _t.get('medicinesSections');

        _t.section = _t.sections[0];
        _t.section.depthLevel = 1;

        _t.calcDepthLevel(_t.section.children, _t.section.id, 2);

        if (_t.sectionId) {
          let sectionInit = _t.findSection(_t.sections, _t.sectionId);

          if (sectionInit) {
            _t.selectSection(sectionInit, 'Medicine', true);
          }
        } else {
          let sectionInit = _t.findSection(_t.sections, _t.medicinesType);

          if (sectionInit) {
            _t.selectSection(sectionInit, 'Medicine', true);
          }
        }
      }

      setTimeout(function () {
        _t.sectionLoaded = true;

        _t.pageLoaded();
      }, 100);
    },
    onChangeTab(tab, index) {
      let _t = this;

      let tabContent = $('.tabs-content');
      $('.tab-content-inner', tabContent).removeClass('active');

      setTimeout(function () {
        _t.tabIndex = index;
        _t.medicinesType = tab.type;

        let sectionInit = _t.findSection(_t.sections, _t.medicinesType);
        _t.selectSection(sectionInit, 'Medicine', true);

        setTimeout(function () {
          $('.tab-content.active .tab-content-inner', tabContent).addClass('active');
        }, 100);
      }, 600);
    }
  }
}
</script>
