<template>
  <div class="page page--equipment-item animate__slow" :class="{ loaded: isLoaded }">

    <div class="detail">
      <router-link :to="{name: 'Equipment'}" class="detail__close m100"><svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" fill="#FFF" d="M76.684 2.437L40.621 38.5l36.063 36.062-2.122 2.122L38.5 40.621 2.438 76.684.316 74.562 36.379 38.5.316 2.437 2.437.316 38.5 36.379 74.563.316l2.121 2.121z"/></svg></router-link>
      <div class="detail__img" :style="equipmentItem.imgStyle" :class="{m25: isMobile}"></div>

      <perfect-scrollbar class="detail__content m25" ref="ps" v-if="!isMobile">
        <div class="detail__name title-magic"><span>{{ equipmentItem.title }}</span></div>

        <div class="btns" v-if="equipmentItem.vimeoId && isMobile">
          <span @click="fancy('https://vimeo.com/' + equipmentItem.vimeoId)" class="btn"><span>{{ get('btnVideo') }}</span></span>
        </div>

        <div class="detail__block" v-if="equipmentItem.textFull" v-html="equipmentItem.textFull"></div>

        <div class="detail__block" v-if="equipmentItem.services">
          <div class="title title--tiny">{{ get('services') }}</div>
          <div class="tags">
            <router-link :to="{name: 'Service', params: {sectionId: section.sectionId, id: section.id}}" class="tag" v-for="section in equipmentItem.services">
              {{ section.name }}
            </router-link>
          </div>
        </div>

        <div class="detail__btns" v-if="photosCnt">
          <template v-for="(photo, index) in photos">
            <a v-if="index == 0" :href="photo.imgHref" data-fancybox="photos" class="btn btn--sm btn--line"><span>{{ get('btnResults') }}</span></a>
            <a v-else :href="photo.imgHref" data-fancybox="photos"></a>
          </template>
        </div>
      </perfect-scrollbar>
      <div class="detail__content m25" v-else>
        <div class="detail__name title-magic"><span>{{ equipmentItem.title }}</span></div>

        <div class="btns" v-if="equipmentItem.vimeoId && isMobile">
          <span @click="fancy('https://vimeo.com/' + equipmentItem.vimeoId)" class="btn"><span>{{ get('btnVideo') }}</span></span>
        </div>

        <div class="detail__block" v-if="equipmentItem.textFull" v-html="equipmentItem.textFull"></div>

        <div class="detail__block" v-if="equipmentItem.services">
          <div class="title title--tiny">{{ get('services') }}</div>
          <div class="tags">
            <router-link :to="{name: 'Service', params: {sectionId: section.sectionId, id: section.id}}" class="tag" v-for="section in equipmentItem.services">
              {{ section.name }}
            </router-link>
          </div>
        </div>

        <div class="detail__btns" v-if="photosCnt">
          <template v-for="(photo, index) in photos">
            <a v-if="index == 0" :href="photo.imgHref" data-fancybox="photos" class="btn btn--sm btn--line"><span>{{ get('btnResults') }}</span></a>
            <a v-else :href="photo.imgHref" data-fancybox="photos"></a>
          </template>
        </div>
      </div>
    </div>

    <div class="bc m25" v-if="equipmentItem.vimeoId && !isMobile">
      <span @click="fancy('https://vimeo.com/' + equipmentItem.vimeoId)" class="btn"><span>{{ get('btnVideo') }}</span></span>
    </div>

    <template v-if="!isMobile">
      <router-link @click="reverseAnimation()" v-if="prevEquipmentItemId" class="slider-prev hover hover--big hover--arrow-prev" :to="{ name: 'EquipmentItem', params: { id: prevEquipmentItemId }}"></router-link>
      <router-link v-if="nextEquipmentItemId" class="slider-next hover hover--big hover--arrow-next" :to="{ name: 'EquipmentItem', params: { id: nextEquipmentItemId }}"></router-link>
    </template>
    <template v-else>
      <div class="navigation">
        <div class="navigation__block">
          <router-link v-if="prevEquipmentItemId" class="navigation__prev active" :to="{ name: 'EquipmentItem', params: { id: prevEquipmentItemId }}"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></router-link>
          <span v-else class="navigation__prev disable"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></span>

          <router-link :to="{name: 'Equipment'}" class="navigation__plus navigation__close"><svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M84.643 44.443H44.501v40.142h-3.797V44.443H.562v-3.797h40.142V.504h3.797v40.142h40.142v3.797z"/></svg></router-link>

          <router-link v-if="nextEquipmentItemId" class="navigation__next active" :to="{ name: 'EquipmentItem', params: { id: nextEquipmentItemId }}"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></router-link>
          <span v-else class="navigation__next disable"><svg xmlns="http://www.w3.org/2000/svg" width="119" height="86" viewBox="0 0 119 86" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M118.345 42.879c-.019.504-.053 1.571.011 1.571-43.457 0-47.398 40.852-47.398 40.852s-4.2-1.131-4.452-1.048c5.731-30.896 28.282-39.542 28.282-39.542H1.039l-.23-1.833H.777l.016-.131-.016-.131h.032l.23-1.833h93.749S72.237 32.138 66.506 1.242c.252.083 4.452-1.048 4.452-1.048s3.941 40.852 47.398 40.852c-.064 0-.03 1.067-.011 1.571h.011l-.005.131.005.131h-.011z"/></svg></span>
        </div>
      </div>
    </template>

  </div>

</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'EquipmentItem',
  mixins: [loader, isMobile, functions],
  emits: ['loaded'],

  props: ['id'],

  data() {
    return {
      pageData: {page: 'EquipmentItem'},
      equipmentItem: {},
      prevEquipmentItemId: false,
      nextEquipmentItemId: false,
      photos: []
    }
  },

  unmounted() {
    this.$store.commit('changeLogoClass', false);
    this.$store.commit('changeTransitionEffects', {isMobile: this.isMobile});
  },

  mounted() {
    var _t = this;

    _t.$store.commit('loaderON');

    _t.$store.subscribe((mutation, state) => {
      if (mutation.type === 'pageLoaded' && mutation.payload.page == _t.pageData.page) {
        _t.onActivated();
      }
    });
  },

  computed: {
    photosCnt() {
      return this.photos.length
    }
  },

  watch: {
    imagesLoaded(val) {
      var _t = this;
      if (_t.magicComplete && val) {
        _t.pageLoaded();
      }
    },
    magicComplete(val) {
      var _t = this;
      if (_t.imagesLoaded && val) {
        _t.pageLoaded();
      }
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.equipmentItem = _t.get('equipmentItem');
      _t.photos = _t.equipmentItem.photos ? _t.equipmentItem.photos : [];
      _t.prevEquipmentItemId = _t.get('prevEquipmentItemId');
      _t.nextEquipmentItemId = _t.get('nextEquipmentItemId');

      _t.$store.commit('changeLogoClass', true);

      for (let i in _t.photos) {
        for (let j in _t.photos[i].img) {
          _t.photos[i].imgHref = _t.photos[i].img[j];
        }
      }

      if (_t.imagesLoaded && _t.magicComplete) {
        _t.pageLoaded();
      } else {
        var images = [];

        _t.equipmentItem.imgStyle = _t.bgImgStyle(_t.equipmentItem.img);

        for (let j in _t.equipmentItem.img) {
          images.push(_t.equipmentItem.img[j]);
        }

        _t.loadImages(images);
        _t.magic();
      }
    },
  }
}
</script>
