<template>
  <modal v-if="modalShowed" class="modal" @close="modalShowed = false">
    <template v-slot:header>
      <div class="modal__header">{{ get('modalHeaderForm') }}</div>
      <span class="modal__close hover rotate-on-hover" @click="modalShowed = false"><svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" fill="#FFF" d="M76.684 2.437L40.621 38.5l36.063 36.062-2.122 2.122L38.5 40.621 2.438 76.684.316 74.562 36.379 38.5.316 2.437 2.437.316 38.5 36.379 74.563.316l2.121 2.121z"/></svg></span>
    </template>
    <template v-slot:body>
      <div class="modal__body">

        <div class="form form--mt">
          <div class="form__row" v-for="(elem, index) in get(formType)">
            <label class="form-elem" :class="{'form-elem--2': elem.type == 'textarea', focused: elem.focused == true}">
              <div class="form-elem__title">{{ elem.title }}<span v-if="elem.required">*</span></div>
              <div class="form-elem__input">
                <input v-model="formElems[formType][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-if="elem.type != 'textarea' && !elem.mask" @focus="elem.focused = true" @blur="elem.focused = false">
                <input v-model="formElems[formType][index]" :type="elem.type" :name="elem.name" :class="elem.class" v-else-if="elem.type != 'textarea' && elem.mask" @focus="elem.focused = true" @blur="elem.focused = false" v-maska="'+7 (###) ###-##-##'">
                <textarea v-model="formElems[formType][index]" v-else :name="elem.name" :class="elem.class" @focus="elem.focused = true" @blur="elem.focused = false"></textarea>
              </div>
            </label>
          </div>
          <div class="form__btns form__btns--2">
            <div class="legend" v-if="!isMobile">{{ get('textRequired') }}</div>
            <span class="btn btn--sm" :class="{'in-action': formInAction == true}" @click="sendForm()"><span>{{ get('btnSend') }}</span></span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal__footer modal__footer--empty"></div>
    </template>
  </modal>
</template>

<script>
import { ref } from 'vue'

import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions.js';

export default {
  name: 'ContactCenterSimple',
  mixins: [isMobile, functions],
  emits: ['openModal'],
  props: {
    pageData: {},
    formType: String
  },
  data() {
    return {
      formElems: {},
      modalShowed: false,
      formInAction: false
    }
  },

  setup(props, {emit}) {
    let formData = ref([])
    let copyShow = ref(false)

    const copyTextToClipboard = (value) => {
      const textarea = document.createElement('textarea')

      textarea.value = value
      textarea.setAttribute('readonly', '')
      textarea.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;'

      document.body.appendChild(textarea)

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        textarea.contentEditable = true
        textarea.readOnly = true

        const range = document.createRange()

        range.selectNodeContents(textarea)

        const selection = window.getSelection()

        selection.removeAllRanges()
        selection.addRange(range)
        textarea.setSelectionRange(0, 999999)
      } else {
        textarea.select()
      }

      let success = false

      try {
        success = document.execCommand('copy')

        copyShow.value = true;
        setTimeout(function() {
          copyShow.value = false;
        }, 5000);
      } catch (err) {
        console.warn(err)
      }

      document.body.removeChild(textarea)
    }

    return {
      copyShow,
      copyTextToClipboard,
    }
  },

  created() {
    this.formElems[this.formType] = []
  },

  methods: {
    showModal() {
      this.modalShowed = true
    },
  }
}
</script>
