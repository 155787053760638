import { createStore } from 'vuex'
import axios from "axios";
import { ref } from 'vue'
import router from './router.js'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            apiUrl: '/api/api.php',

            firstLoad: ref(true),
            enterClassName: 'animated',
            leaveClassName: 'animated slideOutLeft',
            enterClassNameVoile: 'animated',
            leaveClassNameVoile: 'animated fadeOut',
            btnContactCenterClass: ref('btn--xs'),
            btnContactCenterClassDefault: ref('btn--xs'),
            btnContactCenterPosition: ref('top'),
            btnContactCenterPositionDefault: ref('top'),
            logoBg: ref(false),
            logoHidden: ref(false),
            modeDefault: ref('light'),
            mode: ref('light'),
            loader: ref(false),
            pageLoaded: ref(false),
            pagesLoaded: ref({}),
            pages: ref({}),
            sliderPrevStyle: ref(''),
            sliderNextStyle: ref(''),
            reviewsLoaded: ref(''),
            reviewsMore: ref(1),
            formResponseData: ref({}),
            modalHeader: ref(''),
            modalText: ref(''),
            hamburgerHidden: ref(false),
            presentationCloseVisible: ref(false),
        }
    },
    mutations: {
        onFirstLoad: function (state) {
            state.firstLoad = false;
        },
        pageLoaded: function (state, params) {
            state.pageLoaded = true;

            state.pagesLoaded[params.page] = true;
        },
        loaderON(state) {
            state.loader = true;
            console.log('loader is ON')
        },
        loaderOFF(state) {
            state.loader = false;
            console.log('loader is OFF')
        },
        changeTransitionEffects(state, params) {
            if (params.isMobile) {
                state.enterClassName = state.firstLoad ? 'animate__animated' : 'animate__animated animate__fadeIn';
                state.leaveClassName = 'animate__animated animate__fadeOut';
            } else {
                state.enterClassName = state.firstLoad ? 'animate__animated' : 'animate__animated ' + (params.rightOut ? 'animate__slideInLeft' : 'animate__slideInRight');
                state.leaveClassName = 'animate__animated ' + (params.rightOut ? 'animate__slideOutRight' : 'animate__slideOutLeft');
            }
        },
        changeVoileTransitionEffects(state) {
            state.enterClassNameVoile = 'animate__animated animate__fadeIn';
            state.leaveClassNameVoile = 'animate__animated animate__fadeOut';
        },
        changeMode(state, mode) {
            state.mode = mode ? mode : state.modeDefault;
        },
        changeBtnContactCenterPosition(state, position) {
            state.btnContactCenterPosition = position ? position : state.btnContactCenterPositionDefault;
        },
        changeBtnContactCenterClass(state, classBtn) {
            state.btnContactCenterClass = classBtn ? classBtn : state.btnContactCenterClassDefault;
        },
        changeLogoClass(state, enable) {
            state.logoBg = !!enable;
        },
        changeLogoState(state, hidden) {
            state.logoHidden = !!hidden;
        },
        sliderPrevShow(state, show) {
            state.sliderPrevStyle = show ? 'opacity: 1' : '';
        },
        sliderNextShow(state, show) {
            state.sliderNextStyle =  show ? 'opacity: 1' : '';
        },
        onReviewsLoaded(state, data) {
            state.reviewsLoaded = data.list;
            state.reviewsMore = data.more;
        },
        formResponse(state, data) {
            state.formResponseData = data;
        },
        changeModal(state, params) {
            state.modalHeader = params.modalHeader
            state.modalText = params.modalText
        },
        toggleHamburger(state, hide) {
            state.hamburgerHidden = !!hide;
        },
        togglePresentationClose(state, show) {
            state.presentationCloseVisible = !!show;
        }
    },
    actions: {
        async loadReviews ({ commit, state }, params) {

            let response = await axios.get(state.apiUrl,{
                params: {
                    action: 'reviews',
                    page: params.page
                }
            });

            if (response.data.success == false) {
                router.push({name: 'Promo'})
            }

            let data = response.data.data;

            setTimeout(function () {
                commit('onReviewsLoaded', data);
            }, 2000)
        },
        async loadData ({ commit, state }, params) {

            let urlParams = {
                action: 'loadData',
                page: params.page
            }

            if (params.id) {
                urlParams.id = params.id
            }

            let response = await axios.get(state.apiUrl,{
                params: urlParams
            });

            if (response.data.success == false) {
                router.push({name: 'Promo'})
            }

            state.pages[params.page] = response.data.data;

            commit('pageLoaded', params);
        },
        async sendForm ({ commit, state }, params) {

            let response = await axios.get(state.apiUrl,{
                params: params
            });

            commit('formResponse', response.data);
        },
    }
})

export default store;
