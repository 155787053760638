<template>
  <div class="page page--reviews page--flex animate__slow" :class="{ loaded: isLoaded }">

    <div class="page__col">
      <div>
        <div class="title" :class="{'title--mobile-small': isMobile}">{{ get('title') }}</div>

        <div class="text" v-html="get('text')" v-if="!isMobile"></div>

        <div class="btns">
          <span class="btn btn--sm" @click="$emit('openModalReview')"><span>{{ get('btnReview') }}</span></span>
        </div>
      </div>
    </div>
    <perfect-scrollbar class="page__content m25" id="wow-reviews" ref="ps">
      <div class="reviews">
        <div class="review-block wow-animate animate__slow" wow-class="animate__fadeInUpSmall" v-for="(review, index) in reviews">
          <div class="review">
            <div class="review__name">{{ review.name }}</div>
            <div class="review__text" v-html="review.text"></div>
          </div>
          <div class="answer" v-if="review.answer">
            <template v-if="!isMobile">
              <div class="answer__img" :style="review.answer.imgStyle"></div>
              <div class="answer__name">{{ review.answer.name }}</div>
              <div class="answer__text" v-html="review.answer.text"></div>
            </template>
            <template v-else>
              <div class="answer__head">
                <div><div class="answer__img" :style="review.answer.imgStyle"></div></div>
                <div class="answer__name">{{ review.answer.name }}</div>
              </div>
              <div class="answer__text" v-html="review.answer.text"></div>
            </template>
          </div>
        </div>
      </div>
      <div class="reviews-loader" id="reviews-loader" v-if="$store.state.reviewsMore"></div>
    </perfect-scrollbar>

  </div>
</template>

<script>
import {loader} from '@/mixins/loader';
import {isMobile} from '@/mixins/isMobile';
import {functions} from '@/mixins/functions';

export default {
  name: 'Reviews',
  mixins: [loader, isMobile, functions],
  emits: ['loaded', 'openModalReview'],

  data() {
    return {
      pageData: {page: 'Reviews'},
      reviews: [],
      reviewsPage: 1,
    }
  },

  methods: {
    onActivated(){
      var _t = this;
      _t.reviews = _t.get('reviews');

      if (_t.imagesLoaded) {
        _t.pageLoaded();
      } else {
        _t.loadReviewsImages(false)
      }

      if (!isMobile) {
        _t.wowAnimate('#wow-reviews')
        _t.observeLoader()
      } else {
        setTimeout(function() {
          _t.observeLoader()
        }, 2000)
      }
    },
    loadReviewsImages(initWow = true) {
      var _t = this;
      var images = [];

      for (let i in _t.reviews) {
        if (_t.reviews[i].answer && _t.reviews[i].answer.img) {
          if (!_t.reviews[i].answer.imgStyle) {
            _t.reviews[i].answer.imgStyle = _t.bgImgStyle(_t.reviews[i].answer.img);
          }

          for (let j in _t.reviews[i].answer.img) {
            images.push(_t.reviews[i].answer.img[j]);
          }
        }
      }

      _t.loadImages(images, initWow);

      if (initWow) {
        _t.wowAnimate('#wow-reviews')
      }
    },
    observeLoader() {
      let _t = this;

      // Find the item we want to animate on scroll
      var target = document.querySelector('#reviews-loader');

      // Call this function when it enters/leaves the viewport
      var callback = function(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            _t.loadReviews()
          } else {

          }
        });
      };

      // Create our observer
      var observer = new IntersectionObserver(callback, {
        threshold: 0,
        root: document.querySelector(_t.isMobile ? '#wrapper > .page' : '#wow-reviews'),
      });
      observer.observe(target);

      _t.$store.subscribe((mutation, state) => {
        if (mutation.type === 'onReviewsLoaded') {

          for(let i in state.reviewsLoaded) {
            _t.reviews.push(state.reviewsLoaded[i]);
          }

          _t.loadReviewsImages();
        }
      });
    },
    loadReviews() {
      let _t = this;

      _t.$store.dispatch('loadReviews', {
        page: ++_t.reviewsPage
      });
    },
  }
}
</script>
