import { createApp, nextTick } from "vue";

import vueVimeoPlayer from 'vue-vimeo-player'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PerfectScrollbar from 'vue3-perfect-scrollbar' //https://www.npmjs.com/package/vue3-perfect-scrollbar
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css' //https://www.npmjs.com/package/vue3-perfect-scrollbar?activeTab=readme
import VueScrollTo from 'vue-scrollto' //https://github.com/rigor789/vue-scrollto
import Maska from 'maska' //https://github.com/beholdr/maska

import App from './App.vue'

import router from './router.js'
import store from './store.js'
import i18n from './i18n.js'

import Modal from '@/components/include/Modal.vue'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(vueVimeoPlayer)
app.use(PerfectScrollbar)
app.use(Maska)
app.use(VueScrollTo, {
    //container: "body",
    duration: 500,
    lazy: false,
    easing: "ease",
    offset: -50,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
app.use(i18n)

app.directive('click-outside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value

                if ($('.modal--result').length == 0 || $(el).parents('.modal--result').length == 1) {
                    binding.value();
                }
            }
        };

        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
});

app.component('modal', Modal)

app.mount('#app')
